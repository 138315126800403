<template>
    <div class="mb-3">
        <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
        >
            <div class="bg-white rounded">
                <div class="p-24">
                    <app-heading-3>Profile Details</app-heading-3>
                    <div class="d-flex align-items-center mt-2">
                        <div class="mr-2">
                            <b-overlay :show="loading" class="w-100 h-100">
                                <img v-if="profilePic" :src="profilePic" class="rounded-circle" width="100" height="100" alt="">
                                <div v-else class="profile-avatar d-flex justify-content-center align-items-center">
                                    <feather-icon class="" icon="UserIcon" size="32" color="#004bff"/>
                                </div>
                            </b-overlay>
                        </div>
                        <div class="d-block">
                            <button class="btn bg-light-primary text-primary fw-bolder mr-2 confirm-btn" @click="uploadProfilePic()">Upload new photo</button>
                            <div class="upload-text mt-1">Allowed JPG or PNG. Max size of 50MB</div>
                        </div>
                    </div>
                </div>
                <div class="p-24 border-0" v-if="!show">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <app-heading-4 class="d-flex align-items-center mb-12">First Name: <app-normal-text class="ml-8">{{toCapitalize(user_data.first_name)}}</app-normal-text></app-heading-4>
                        </div>
                        <div class="col-12 col-md-6">
                            <app-heading-4 class="d-flex align-items-center mb-12">Last Name: <app-normal-text class="ml-8">{{toCapitalize(user_data.last_name)}}</app-normal-text></app-heading-4>
                        </div>
                        <div class="col-12 col-md-6">
                            <app-heading-4 class="d-flex align-items-center mb-12">Email: <app-normal-text class="ml-8">{{user_data.email}}</app-normal-text></app-heading-4>
                        </div>
                        <div class="col-12 col-md-6">
                            <app-heading-4 class="d-flex align-items-center mb-12">Phone Number: <app-normal-text class="ml-8">{{user_data.phone_number}}</app-normal-text></app-heading-4>
                        </div>
                        <!-- <div class="col-12 col-md-6">
                            <app-heading-4 class="d-flex align-items-center mb-12">Country: <app-normal-text class="ml-8">{{user_data.country}}</app-normal-text></app-heading-4>
                        </div> -->
                        <div class="col-12 col-md-6">
                            <app-heading-4 class="d-flex align-items-center mb-12">Role: <app-normal-text class="ml-8">{{toCapitalize(user_data.role)}}</app-normal-text></app-heading-4>
                        </div>
                        <div class="col-12 col-md-6">
                            <app-heading-4 class="d-flex align-items-center mb-12">Subscription: <b-badge variant="light-primary" class="ml-8">{{user_data.subscription}}</b-badge></app-heading-4>  
                        </div>
                    </div>
                    <div class="mt-1">
                        <b-button variant="primary" @click="edit">Edit Profile</b-button>
                    </div>          
                </div>
                <div class="p-24 border-0" v-if="show">
                    <validation-observer ref="numberForm" >
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <validation-provider #default="{ errors }" name="First name" vid="first_name" rules="required|name">
                                    <app-text-input label="First Name" v-model="user_data.first_name" placeholder="jhon" :errors="errors" @onKeydown="isLetter"/>
                                </validation-provider>
                            </div>
                            <div class="col-12 col-md-6">
                                <validation-provider #default="{ errors }" name="Last name" vid="last_name" rules="required|name">
                                    <app-text-input label="Last Name" v-model="user_data.last_name" placeholder="Doe" :errors="errors" @onKeydown="isLetter"/>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <app-text-input label="Email" disabled v-model="user_data.email" placeholder="jhon.doe@gmail.com"/>
                            </div>
                            <div class="col-12 col-md-6">
                                <validation-provider #default="{ errors }" name="Phone number" vid="phone_number" rules="required|phoneno">
                                    <app-text-input label="Phone Number" v-model="user_data.phone_number" placeholder="xxx xxx xxxx" :errors="errors" @onKeydown="isPhoneNumber"/> 
                                </validation-provider>
                            </div>
                        </div>
                    </validation-observer>
                    <div class="row">
                        <div class="col-12">
                            <b-button variant="primary" :disabled="updateLoading" @click="updateUserInfo()">Save Changes</b-button>
                            <button class="btn bg-light-secondary text-secondary fw-bolder ml-2 confirm-btn" @click="cancelEdit">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white rounded p-24 border-0 my-2" v-if="show">
                <app-heading-3>Delete Account</app-heading-3>
                <div class="bg-light-danger text-danger p-1 rounded mt-1">
                    <div class="text-bold">Are you sure you want to delete your account?</div>
                    <div class="text-simple">Once you delete your account, there is no going back. Please be certain.</div>
                </div>
                <b-form-group class="mt-1">
                    <b-form-checkbox
                    v-model="agree"
                    name="checkbox-1"
                    class="d-flex align-items-center cursor-pointer"
                    >
                    <app-normal-text>
                        I confirm my account deactivation
                    </app-normal-text>
                    </b-form-checkbox>
                </b-form-group>
                <div class="mt-1">
                    <b-button :disabled="!agree || deleteLoading" variant="danger" @click="deleteUser()">Deactivate Account</b-button>
                </div>     
            </div>
            <upload-profile-picture ref="uploadProfile" @onSaved="getUserDetails()"/>
        </b-overlay>
    </div>    
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import AppHeading2 from '@/components/app-components/AppHeading2.vue'
import AppHeading4 from '@/components/app-components/AppHeading4.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppNameInput from '@/components/app-components/AppNameInput.vue'
import AppPhoneInput from '@/components/app-components/AppPhoneInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import {BBadge, BButton, BFormGroup, BFormCheckbox, BOverlay} from 'bootstrap-vue'
import UploadProfilePicture from '@/components/functional-components/other/settings/UploadProfilePic.vue'
import {user_details} from '@/apis/auth'
import {update_user,delete_user} from "@/apis/user-settings"
import { eventBus } from '@/utils/eventbus'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, } from '@validations'
extend('phoneno', value => {
      var regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if (regex.test(value)){
        return true
      }else{
        return 'Please enter a valid phone numeber (e.g. 223....)'
      }  
    } 
  )
  extend('name', value => {
      var regex = /^[A-Za-z]+$/;
      if (regex.test(value)){
        return true
      }else{
        return 'Please enter valid name'
      }  
    } 
  )
export default {
    components: {
        AppHeading3,
        BBadge,
        AppHeading2,
        AppHeading4,
        AppNormalText,
        BButton,
        BFormGroup,
        BFormCheckbox,
        AppTextInput,
        AppSelectBox,
        UploadProfilePicture,
        BOverlay,
        ValidationObserver,
        ValidationProvider,
        AppNameInput,
        AppPhoneInput
    },
    data(){
        return{
            show:false,
            user_data:{
                role:'',
                first_name:'',
                last_name:'',
                email:'',
                phone_number:'',
                subscription:'Free',
            },
            agree:false,
            profilePic:'',
            loading:false,
            updateLoading:false,
            deleteLoading:false,
            required,
            defaultData:null
        }
    },
    mounted(){
        this.getUserDetails()
    },
    methods:{
        uploadProfilePic(){
            this.$refs.uploadProfile.showModal()
        },
        getUserDetails(){
            this.loading=true
            this.profilePic=''
            user_details().then(resp=>{
                this.loading=false
                this.user_data.first_name=resp.data.first_name
                this.user_data.last_name=resp.data.last_name
                this.user_data.email=resp.data.email
                this.user_data.phone_number=resp.data.phone_number.slice(2)
                this.user_data.role=resp.data.role
                this.user_data.subscription=resp.data.subscriptionTier.name
                this.profilePic=resp.data.profilePicture
                this.setProfilePic(resp.data.profilePicture)
            }).catch(err=>{
                this.loading=false
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            })
        },
        setProfilePic(data){
            let user = JSON.parse(localStorage.getItem('user'));
            localStorage.setItem('user',JSON.stringify({
                ...user,
                 profilePicture:data,
            }))
            eventBus.$emit('updateProfilePic',data)
        },
        toCapitalize(name){
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        },
        updateUserInfo(){
            this.updateLoading=true;
            let data= new FormData();
            data.append('first_name',this.user_data.first_name);
            data.append('last_name',this.user_data.last_name);
            data.append('phone_number',`+1${this.user_data.phone_number}`);
            update_user(data).then(resp=>{
                this.$bvToast.toast('Profile updated successfully', {
                    title: 'Success',
                    variant:'success',
                    solid: true,
                });
                this.getUserDetails()
                this.updateLoading=false
                this.show=false
            }).catch(err=>{
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                this.updateLoading=false
            });
        },
        deleteUser(){
            this.deleteLoading=true;
            delete_user().then(resp=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Success',
                    variant:'success',
                    solid: true,
                });
                this.deleteLoading=false;
                localStorage.removeItem('user')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                this.$router.push('/login')
            }).catch(err=>{
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                this.deleteLoading=false;
            })
        },
        isLetter(e) {
            if(![8,9,37,39,46].includes(e.keyCode)){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault() ; // If not match, don't add to input text
            }
            
        },
        isPhoneNumber(e){
            if(![8,9,37,39,46].includes(e.keyCode)){
                let char = String.fromCharCode(e.keyCode); // Get the character
                if(/[0-9]/.test(char)&& this.user_data.phone_number.length<10) return true;
                // if(this.user_data.phone_number.length<11) return true; // Match with regex 
                else e.preventDefault(); 
            }
        },
        edit(){
            this.show=true;
            this.defaultData={
                ...this.user_data
            }
        },
        cancelEdit(){
            this.show=false;
            this.user_data=this.defaultData
        }
    }
}
</script>
<style scoped>
.p-24{
    padding: 24px;
    border-bottom: 1px solid #DBDADE;
}
.ml-8{
    margin-left: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
}
.upload-text{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
}
.text-bold{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}
.text-simple{
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
}
.profile-avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e0e9ff;
}
</style>